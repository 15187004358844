import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../node_modules/jquery/dist/jquery.min.js";
import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import React from 'react';
import ReactDOM from 'react-dom';
import App from './framehameha/app';

ReactDOM.render(<App />,document.getElementById('root'));
