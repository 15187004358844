import { LearningData } from "../../models/app";

const LearningOffenseData: LearningData[] = [
    {
        title: 'Is Offense really the best Defense?',
        details: ["Before we jump straight into basic offensive ideas, combos and ruining your enemies lifebars. Let's take a moment to make an even more crucial decision. What team am I going to use to rule the Dragonball Fighterz world? With a 38 person roster (if we include DLC)  and 3 characters on a team, you are given a heavy handed amount of freedom when it comes to your team. Are you aiming to play high tier? Do you have a personal fan favorite that you want to build a team around? What kind of things should you focus on when building a team?",''],
        embedContent: 'https://www.youtube.com/embed/kUWEJBpX--I',
        contributor: 'https://www.youtube.com/channel/UC4n3mpDrD-noB7uMkKSD00Q',
    },
    {
        title: 'Combo Structure Basics',
        details: ["You can only combo from L > M > H moves. S normals can cancel into H normals if on the ground. Example: 5L > 2M > 5M > 5H > 5S", "You can perform any variation of (2) L moves during a combo. Meaning, you can either 5L > 5L; 2L > 2L; 5L > 2L; 2L > 5L(slight exemption you can do 2L > 5L > auto-combo).", "You can only do two M normals during a combo, and 6M does not combo into normals, it requires and assist to extend on hit. 2H cannot cancel into any S normals. Similar to the ground, j.L can only be done twice during a combo, however jM only connects once. While airborne, you can use j.M into j.L, this is referred to as a Reverse Beat, since it is going backwards based on the Normal progression."],
        embedContent: 'https://www.youtube.com/embed/XtYw3mt3HMw',
        contributor: 'https://www.youtube.com/channel/UCOgaIuQYGr6ow_jbote4BKA',
    },
    {
        title: 'Super Moves',
        details: ["Super Moves allow you to spend meter to deal high amounts of damage to the opponent. Supers have two main variants, level 1's and level 3's. Doing Supers randomly is not the most recommended choice, they can be blocked/evaded, have a decent amount of recovery and use meter. One exception is most level 3's are considered reversals, so they can counter the opponent if their is a gap in their pressure. ", "The most optimal application is using Supers during a combo. At any point during a hits stun, you can activate an applicable super and add more damage. If you manage to knock the character down and put them into a sliding knockdown state, you can follow up with a super for M-M-Maximum Damage!"],
        embedContent: 'https://www.youtube.com/embed/wyEE7O0EpNo',
        contributor: 'https://www.youtube.com/channel/UCOgaIuQYGr6ow_jbote4BKA',
    },
    {
        title: 'Universal Fuzzy',
        details: ["The Universal Fuzzy is an option that exists to put your opponent in a 100% 50/50 guessing scenario. It's dirty, its good and can benefit you in know how to defend against it and use it. Keep in mind, based on the Season 3 patch, universal fuzzy still exists but is not as often found."],
        embedContent: 'https://www.youtube.com/embed/jr16kzxvfmA',
        contributor: 'https://www.youtube.com/channel/UC4n3mpDrD-noB7uMkKSD00Q',
    },
]
export default LearningOffenseData;